import React, { ReactElement, PropsWithChildren } from 'react';
import Button from '@mui/material/Button';
import getDate from 'date-fns/getDate';

/**
 * Presentational component that just display the dates in the date picker
 */
const DayButton = (props: any): ReactElement => {
  const { outsideCurrentMonth, wrapperClassName, date, onClick } = props;

  return (
    <Button
      disabled={outsideCurrentMonth}
      className={wrapperClassName}
      disableElevation={true}
      disableRipple={true}
      onClick={onClick}
    >
      {getDate(date)}
    </Button>
  );
};

const isEqual = (prevProps: PropsWithChildren<any>, nextProps: PropsWithChildren<any>): boolean => {
  return (
    prevProps.outsideCurrentMonth === nextProps.outsideCurrentMonth &&
    prevProps.wrapperClassName === nextProps.wrapperClassName &&
    prevProps.date?.getTime() === nextProps.date?.getTime() &&
    prevProps.onClick === nextProps.onClick
  );
};

export default React.memo(DayButton, isEqual);
