import React, { PropsWithChildren, ReactElement } from 'react';
import { PulseScrollbarProps } from './pulse-scrollbar-types';
import { Scrollbars } from 'react-custom-scrollbars';

export const PulseScrollbar = (props: PropsWithChildren<PulseScrollbarProps>): ReactElement => {
  const { children, ScrollbarProps } = props;

  return (
    <Scrollbars autoHide {...ScrollbarProps}>
      {children}
    </Scrollbars>
  );
};

export default React.memo<PulseScrollbarProps>(PulseScrollbar);
