import React, { useEffect, useState, ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import TimesheetErrorItem from '../../../timesheeterroritem/timesheeterroritem';
import styles from './timesheet-error-report-section.module.scss';
import Label from '../../../label/label';
import { isMobileDevice } from 'pulse-commons/helpers';
import { Colors, Sizes, ButtonVariants } from 'pulse-commons/types';
import { TimesheetErrorReportSectionPropsType } from './timesheet-error-report-section-type';
import { TimesheetErrorDataItem } from '../../../timesheeterroritem/timesheeterroritem-types';
import PCSSpinner, { PCSSpinnerVariants } from '../../../spinner/pcs-spinner';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';

const EXPANSION_PANEL_EXPAND_TEXT = 'EXPAND';
const EXPANSION_PANEL_COLLAPSE_TEXT = 'COLLAPSE';

const TimesheetErrorReportSection = (props: TimesheetErrorReportSectionPropsType): ReactElement => {
  const {
    errorTypeId,
    loadMoreItems,
    resubmitTimesheetErrorItem,
    timesheetErrorReportSectionDetails,
    title,
    toggleTimesheetErrorItem,
  } = props;

  const {
    isLoading,
    attributes,
    timesheetErrorItems: { data, meta: { pagination } } = {
      data: null,
      meta: {
        pagination: null,
      },
    },
  } = timesheetErrorReportSectionDetails;

  const [buttonText, setButtonText] = useState(EXPANSION_PANEL_EXPAND_TEXT);
  const [showLoadMoreSpinner, setShowLoadMoreSpinner] = useState(isLoading);
  const [hasMore, setHasMore] = useState(false);
  const [isSectionLoading, setIsSectionLoading] = useState(isLoading);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isDeselectEnabled, setIsDeselectEnabled] = useState(false);
  const [selectedInCategoryCount, setSelectedInCategoryCount] = useState(0);

  /** Handles changing the text on the expand/collapse button */
  const toggleButtonText: AccordionProps['onChange'] = (event, expanded): void => {
    expanded ? setButtonText(EXPANSION_PANEL_COLLAPSE_TEXT) : setButtonText(EXPANSION_PANEL_EXPAND_TEXT);
  };
  /** Handles click for the load more button */
  const loadMoreTimesheetErrorItems = (): void => {
    loadMoreItems({
      errorTypeId,
      page: {
        per_page: 25,
        number: (pagination?.current_page || 1) + 1,
      },
    });
  };

  const timesheetErrorCount = data ? data.length : 0;
  const timesheetErrorLabel = timesheetErrorCount > 1 ? 'issues' : 'issue';
  const timesheetErrorCountLabel = isMobileDevice()
    ? `${timesheetErrorCount}`
    : `${timesheetErrorCount} ${timesheetErrorLabel}`;

  const selectAllErrorsInView = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    data &&
      data.map((timesheetErrorItem: TimesheetErrorDataItem) =>
        toggleTimesheetErrorItem(errorTypeId, timesheetErrorItem.id, true),
      );
  };
  const deselectAllErrorsInView = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    data &&
      data.map((timesheetErrorItem: TimesheetErrorDataItem) =>
        toggleTimesheetErrorItem(errorTypeId, timesheetErrorItem.id, false),
      );
  };

  /** Hide the spinner in the load more button if the data has changed */
  useEffect(() => {
    setShowLoadMoreSpinner(isLoading);
    pagination && setHasMore(pagination.current_page < pagination.last_page);
    setIsSectionLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      const countOfTimesheetErrorItems = data.length;
      const selectedInCategory = data.filter(
        (timesheetErrorItem: TimesheetErrorDataItem) => timesheetErrorItem.selected,
      );
      setSelectedInCategoryCount(selectedInCategory.length);
      setIsAllSelected(countOfTimesheetErrorItems === selectedInCategory.length);
      setIsDeselectEnabled(selectedInCategory.length > 0);
    }
  }, [data]);

  return (
    <>
      <Accordion
        classes={{
          root: styles['timesheet-error-report__expansion-panel'],
        }}
        onChange={toggleButtonText}
        slotProps={{
          transition: { unmountOnExit: true },
        }}
      >
        <AccordionSummary
          classes={{
            root: styles['timesheet-error-report__expansion-header'],
            content: styles['timesheet-error-report-section__header-content'],
          }}
          expandIcon={
            <PulseButtonBase
              classes={[styles['timesheet-error-report__expansion-btn']]}
              aria-label="expand"
              label={buttonText}
            ></PulseButtonBase>
          }
        >
          <div className={styles['timesheet-error-report-section__header--left']}>
            <div className={styles['timesheet-error-report-section__title']}>
              <Typography variant="h6">{title}</Typography>
            </div>
            <Label text={timesheetErrorCountLabel} color={Colors.success} size={Sizes.sm} />
            {selectedInCategoryCount > 0 && (
              <Label text={`${selectedInCategoryCount.toString()} selected`} color={Colors.warning} size={Sizes.sm} />
            )}
            {isSectionLoading && !isMobileDevice() && <PCSSpinner variant={PCSSpinnerVariants.circularSpinner} />}
          </div>
          {!isMobileDevice() && (
            <div className={styles['timesheet-error-report-section__header--right']}>
              {buttonText === EXPANSION_PANEL_COLLAPSE_TEXT && (
                <>
                  <PulseButtonBase
                    aria-label="selectall"
                    label="Select all"
                    disabled={isAllSelected}
                    onClick={selectAllErrorsInView}
                  ></PulseButtonBase>
                  <PulseButtonBase
                    aria-label="deselectall"
                    disabled={!isAllSelected && !isDeselectEnabled}
                    label="Deselect all"
                    onClick={deselectAllErrorsInView}
                  ></PulseButtonBase>
                </>
              )}
            </div>
          )}
        </AccordionSummary>
        <Divider />
        <AccordionDetails
          classes={{
            root: styles['timesheet-error-report__details'],
          }}
        >
          <div className={styles['timesheet-error-report__resolution']}>
            <p>
              <span>Why is this happening?</span> {attributes.tooltip}
            </p>
            <p>
              <span>How to fix this error?</span> {attributes.support_action}
            </p>
          </div>
          {data &&
            data.map((timesheetErrorItem: TimesheetErrorDataItem) => {
              return (
                <TimesheetErrorItem
                  key={`${errorTypeId}-${timesheetErrorItem.submission_id}`}
                  errorData={timesheetErrorItem}
                  selectHandler={toggleTimesheetErrorItem}
                  resubmitHandler={resubmitTimesheetErrorItem}
                />
              );
            })}
          <PulseButtonBase
            variant={ButtonVariants.contained}
            classes={[styles['timesheet-error-report-section__load-more']]}
            onClick={loadMoreTimesheetErrorItems}
            disabled={showLoadMoreSpinner || !hasMore}
            showLoadMoreSpinner={showLoadMoreSpinner}
          >
            Load More ({pagination && pagination.per_page})
          </PulseButtonBase>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const isEqual = (
  prevProps: TimesheetErrorReportSectionPropsType,
  nextProps: TimesheetErrorReportSectionPropsType,
): boolean => {
  return prevProps.timesheetErrorReportSectionDetails === nextProps.timesheetErrorReportSectionDetails;
};

export default React.memo(TimesheetErrorReportSection, isEqual);
