import { TextField } from '@mui/material';
import { InputAdornmentProps } from '@mui/material/InputAdornment';
import { InputLabelProps } from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker as KeyboardDatePicker,
  DatePickerProps as KeyboardDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import PulseFieldError from 'components/pulse-field-error/pulse-field-error';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import _ from 'lodash';
import { timezoneDateFormat } from 'pulse-commons/helpers';
import React, { ReactElement, Ref, useEffect, useMemo, useRef } from 'react';
import { usePulseDatePickerDispatch, usePulseDatePickerState } from '../context/pulse-datepicker-context';
import { Actions } from '../reducer/pulse-datepicker-reducer';
import PulseDatePickerInputTypes from './pulse-datepicker-input-types';
import styles from './pulse-datepicker-input.module.scss';

const INPUT_VARIANT = 'outlined';

const inputAdornmentConfig: Partial<InputAdornmentProps> = {
  className: clsx(styles['pulse-datepicker-input__inputAdornment']),
  position: 'start',
  disablePointerEvents: true,
};

const inputLabelConfig = {
  variant: 'standard' as InputLabelProps['variant'],
  disableAnimation: true,
  shrink: true,
  classes: {
    root: 'date-picker__label',
  },
};

const inputConfig = {
  notched: false,
  classes: {
    root: styles['pulse-datepicker-inputRoot'],
    input: styles['pulse-datepicker-input__input'],
    focused: styles['pulse-datepicker-input--focused'],
    notchedOutline: styles['pulse-datepicker-input__notchedOutline'],
  },
  fullWidth: true,
  variant: INPUT_VARIANT,
  error: false,
};

const commonProps: Partial<KeyboardDatePickerProps<any, Date>> = {
  inputFormat: timezoneDateFormat(),
  OpenPickerButtonProps: {
    classes: {
      root: styles['pulse-datepicker-input__keyboardButtonRoot'],
    },
    disableFocusRipple: true,
  },
  components: {
    OpenPickerIcon: () => (
      <PulseIcon classes={{ icon: clsx('fal fa-calendar-alt', styles['pulse-datepicker-input__icon']) }} iconName="" />
    ),
  },
  InputAdornmentProps: inputAdornmentConfig,
  label: '',
};

const DatePickerInput = (props: PulseDatePickerInputTypes): ReactElement => {
  const {
    classes,
    displayType = 'linear',
    error,
    fullWidth = false,
    isInvalid,
    isRequired,
    KeyboardDatePickerEndProps,
    KeyboardDatePickerStartProps,
    labelCommon = 'Date Range',
    labelEnd,
    labelStart,
    placeholderStart,
    placeholderEnd,
    range,
    spacer = 'to',
    disabled = false,
  } = props;

  const datePickerContextState = usePulseDatePickerState();
  const datePickerContextDispatch = usePulseDatePickerDispatch();
  const datePickerInputControlCtnRef: Ref<HTMLDivElement> = useRef(null);
  const startDateInputRef: Ref<HTMLInputElement> = useRef(null);
  const endDateInputRef: Ref<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (range && startDateInputRef.current && endDateInputRef.current) {
      datePickerContextState.inputFocus === 'start' && startDateInputRef.current.focus();
      datePickerContextState.inputFocus === 'end' && endDateInputRef.current.focus();
    }
    if (!range && startDateInputRef.current && datePickerContextState.inputFocus === null) {
      startDateInputRef.current.blur();
    }
  }, [datePickerContextState.inputFocus, datePickerContextState.values]);

  const setDateAtPositionStart = (): void => {
    datePickerContextDispatch({
      type: Actions.showCalendarPicker,
      payload: {
        anchorEl: datePickerInputControlCtnRef,
        inputFocus: 'start',
      },
    });
  };

  const setDateAtPositionEnd = (): void => {
    datePickerContextDispatch({
      type: Actions.showCalendarPicker,
      payload: {
        anchorEl: datePickerInputControlCtnRef,
        inputFocus: 'end',
      },
    });
  };

  const handleRangeStartChange = _.debounce((value: Date | null, keyboardInputValue?: string | null):
    | void
    | boolean => {
    datePickerContextDispatch({
      type: Actions.setStartDate,
      payload: {
        inputValues: {
          start: keyboardInputValue,
        },
        values: {
          startDate: value,
        },
      },
    });
  }, 500);

  const handleRangeEndChange = _.debounce((value: Date | null, keyboardInputValue?: string | null): void => {
    datePickerContextDispatch({
      type: Actions.setEndDate,
      payload: {
        inputValues: {
          end: keyboardInputValue,
        },
        values: {
          endDate: value,
        },
      },
    });
  }, 500);

  const handleSingleChange = _.debounce((date: Date | null): void => {
    datePickerContextDispatch({
      type: Actions.setSingle,
      payload: {
        date,
      },
    });
  }, 500);

  const getDatePickerInputProps = (
    datePickerProps?: Partial<KeyboardDatePickerProps<any, Date>>,
  ): Partial<KeyboardDatePickerProps<any, Date>>['InputProps'] => {
    const { classes: datePickerInputClasses } = datePickerProps?.InputProps || {};
    return {
      ...inputConfig,
      ...datePickerProps?.InputProps,
      classes: {
        root: clsx(
          inputConfig.classes.root,
          datePickerInputClasses?.root,
          disabled && styles['pulse-datepicker-input--disabled'],
        ),
        input: clsx(inputConfig.classes.input, datePickerInputClasses?.input),
        focused: clsx(inputConfig.classes.focused, datePickerInputClasses?.focused),
      },
    };
  };

  return (
    <div className={clsx(styles['pulse-datepicker-input__root'], fullWidth && styles['fullWidth'], classes?.root)}>
      {range && labelCommon && (
        <label className={clsx(styles['pulse-datepicker-input__label'], classes?.labelCommon)}>{labelCommon}</label>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          data-testid="date-picker__input-control-ctn"
          className={clsx(
            styles['pulse-datepicker-input__controlCtn'],
            styles[`pulse-datepicker-input__controlCtn--${displayType}`],
          )}
          ref={datePickerInputControlCtnRef}
        >
          <span className={clsx(styles['pulse-datepicker-input__ctn'], fullWidth && styles['fullWidth'])}>
            {labelStart && (
              <label
                className={clsx([
                  styles['pulse-datepicker-input__label'],
                  disabled && styles['pulse-datepicker-input__label--disabled'],
                  classes?.labelEnd,
                ])}
              >
                {labelStart}
                {isRequired && <span className={styles['pulse-datepicker-input__label--required']}>*Required</span>}
              </label>
            )}
            <KeyboardDatePicker
              {...commonProps}
              className={clsx(classes?.start, isInvalid && styles['pulse-datepicker-input--invalid'])}
              inputRef={startDateInputRef}
              InputProps={{
                ...getDatePickerInputProps(KeyboardDatePickerStartProps),
                onFocus: setDateAtPositionStart,
              }}
              onChange={range ? handleRangeStartChange : handleSingleChange}
              value={datePickerContextState.values?.startDate || null}
              disabled={disabled}
              renderInput={params => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: placeholderStart || 'Select a date',
                  }}
                  InputLabelProps={inputLabelConfig}
                />
              )}
              {...KeyboardDatePickerStartProps}
            />
          </span>
          {range &&
            useMemo(
              () => (
                <span className={clsx(styles['pulse-datepicker-input__rangeSpacer'], classes?.rangeSpacer)}>
                  {typeof spacer === 'string' ? spacer : <PulseIcon {...spacer} />}
                </span>
              ),
              [],
            )}
          {range && (
            <span className={styles['pulse-datepicker-input__ctn']}>
              {labelEnd && (
                <label
                  className={clsx([
                    styles['pulse-datepicker-input__label'],
                    disabled && styles['pulse-datepicker-input__label--disabled'],
                    classes?.labelEnd,
                  ])}
                >
                  {labelEnd}
                  {isRequired && <span className={styles['pulse-datepicker-input__label--required']}>*Required</span>}
                </label>
              )}
              <KeyboardDatePicker
                {...commonProps}
                className={clsx(classes?.end, isInvalid && styles['pulse-datepicker-input--invalid'])}
                inputRef={endDateInputRef}
                InputProps={{
                  ...getDatePickerInputProps(KeyboardDatePickerEndProps),
                  onFocus: setDateAtPositionEnd,
                }}
                onChange={handleRangeEndChange}
                value={datePickerContextState.values?.endDate || null}
                disabled={disabled}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: placeholderEnd || 'Select end date',
                    }}
                    InputLabelProps={inputLabelConfig}
                  />
                )}
                {...KeyboardDatePickerEndProps}
              />
            </span>
          )}
          {isInvalid && error && <PulseFieldError error={error} />}
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerInput;
