import { ButtonVariants, IconSizes } from 'pulse-commons/types';
import { Actions } from '../reducer/pulse-datepicker-reducer';
import clsx from 'clsx';
import addMonths from 'date-fns/addMonths';
import endOfDay from 'date-fns/endOfDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import { isMobileDevice } from 'pulse-commons/helpers';
import { usePulseDatePickerDispatch, usePulseDatePickerState } from '../context/pulse-datepicker-context';
import { PulseDatepickerCalendarProps } from './pulse-datepicker-calendar-types';
import DayButton from './pulse-datepicker-day-component';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ExportedDayPickerProps } from '@mui/x-date-pickers/CalendarPicker/DayPicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers/StaticDatePicker';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseIconButton from 'components/pulse-icon-button/pulse-icon-button';
import isValid from 'date-fns/isValid';
import startOfDay from 'date-fns/startOfDay';
import { isDate } from 'lodash';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import styles from './pulse-datepicker-calendar.module.scss';
import PresetDateRangesComponent from './pulse-preset-component';
import { PickerStateProps } from '@mui/x-date-pickers/internals';

const today = new Date();

const staticDatePickerCommonProps: Partial<StaticDatePickerProps<Date, Date | null>> = {
  dayOfWeekFormatter: date => date,
  inputFormat: 'dd/MM/yyyy',
  reduceAnimations: true,
  views: ['day'],
  openTo: 'day',
  displayStaticWrapperAs: 'desktop',
};

const PulseDatepickerCalendar: FC<PulseDatepickerCalendarProps> = props => {
  const { enablePresets, enableReset, range, showApplyButton } = props;

  const datePickerContextState = usePulseDatePickerState();
  const datePickerContextDispatch = usePulseDatePickerDispatch();

  /** @member { Date } selectedDate - This is used to control which month is shown in the calendars */
  const [selectedDate, setSelectedDate] = useState(datePickerContextState.values?.startDate || today);

  useEffect(() => {
    const startDate = datePickerContextState.values?.startDate;
    if (startDate) {
      const isStartDateValid = isValid(startDate);
      isStartDateValid ? setSelectedDate(startDate) : setSelectedDate(new Date());
    }
  }, [datePickerContextState.values?.startDate]);

  /** Handles resetting the date range picker state to its original state */
  const resetCalendar = (): void => {
    setSelectedDate(datePickerContextState.values?.startDate || today);
  };

  const handleClickAway = (): void => {
    datePickerContextDispatch({
      type: Actions.hideCalendarPicker,
    });
  };

  /** Handle clicking on the apply button in the pop over if the option is turned on */
  const onClickApplyHandler = (): void => {
    handleClickAway();
  };

  /** Function to handle clicks on the calendar pickers */
  const handleCalendarRangeChange: PickerStateProps<Date, Date | null>['onChange'] = date => {
    if (!isDate(date)) {
      return;
    }
    if (datePickerContextState.values?.startDate && isBefore(date, datePickerContextState.values?.startDate)) {
      datePickerContextDispatch({
        type: Actions.setStartDate,
        payload: {
          inputFocus: 'end',
          values: {
            startDate: date,
          },
        },
      });
    } else if (datePickerContextState.values?.endDate && isAfter(date, datePickerContextState.values?.endDate)) {
      datePickerContextDispatch({
        type: Actions.setEndDate,
        payload: {
          inputFocus: 'start',
          values: {
            endDate: date,
          },
        },
      });
    } else {
      datePickerContextDispatch({
        type: Actions.setDate,
        payload: {
          date,
        },
      });
    }
  };

  const handleCalendarSingleChange: PickerStateProps<any, any>['onChange'] = date => {
    datePickerContextDispatch({
      type: Actions.setSingle,
      payload: {
        date,
      },
    });
  };

  /** Handles click on the right arrow button to navigate to future months */
  const increaseMonth = (): void => {
    setSelectedDate(addMonths(selectedDate, 1));
  };

  /** Handles click on the left arrow button to navigate to older months */
  const decreaseMonth = (): void => {
    setSelectedDate(addMonths(selectedDate, -1));
  };

  const renderHighlightDateRange: ExportedDayPickerProps<Date | null>['renderDay'] = (
    date,
    selectedDate,
    { outsideCurrentMonth },
  ): ReactElement => {
    const start = datePickerContextState.values?.startDate && startOfDay(datePickerContextState.values?.startDate);
    const end = datePickerContextState.values?.endDate && endOfDay(datePickerContextState.values?.endDate);
    const isRangeValid = start && end && (isBefore(start, end) || isSameDay(start, end));
    const isFirstDay = start && date && isSameDay(date, start);
    const isLastDay = end && date && isSameDay(date, end);

    let wrapperClassName = clsx(
      styles['pulse-datepicker-calendar__day'],
      date && isToday(date) && styles['pulse-datepicker-calendar__day--today'],
      (isFirstDay || isLastDay) && styles['pulse-datepicker-calendar__day--inRange'],
    );

    if (isRangeValid) {
      const dayIsBetween = start && end && date && isWithinInterval(date, { start, end });
      wrapperClassName = clsx(
        styles['pulse-datepicker-calendar__day'],
        date && isToday(date) && styles['pulse-datepicker-calendar__day--today'],
        (dayIsBetween || isFirstDay || isLastDay) && styles['pulse-datepicker-calendar__day--inRange'],
        isFirstDay && styles['pulse-datepicker-calendar__day--startDate'],
        isLastDay && styles['pulse-datepicker-calendar__day--endDate'],
      );
    }
    return (
      <DayButton
        key={date?.toString()}
        date={date}
        wrapperClassName={wrapperClassName}
        outsideCurrentMonth={outsideCurrentMonth}
        onClick={() => {
          range ? handleCalendarRangeChange(date) : handleCalendarSingleChange(date);
        }}
      />
    );
  };
  return (
    <div className={styles['pulse-datepicker-calendar__root']}>
      {enablePresets && <PresetDateRangesComponent onClick={(): void => console.log('test')} />}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PulseIconButton
          classes={{
            root: clsx(styles['pulse-datepicker-calendar__nav'], styles['pulse-datepicker-calendar__navPrev']),
            pulseIcon: {
              icon: 'fal fa-chevron-left',
            },
          }}
          handleClick={decreaseMonth}
          iconName=""
          size={IconSizes.lg}
        />
        <div className={styles['pulse-datepicker-calendar__center']}>
          <div data-testid="date-picker__calendars" className={styles['pulse-datepicker-calendar__ctn']}>
            <StaticDatePicker
              {...staticDatePickerCommonProps}
              value={selectedDate}
              renderInput={params => <TextField {...params} />}
              renderDay={renderHighlightDateRange}
              onChange={() => {}}
              className={styles['date-picker-static']}
            />
            {!isMobileDevice() && range && (
              <StaticDatePicker
                {...staticDatePickerCommonProps}
                value={addMonths(selectedDate, 1)}
                renderInput={params => <TextField {...params} />}
                renderDay={renderHighlightDateRange}
                onChange={() => {}}
                className={styles['date-picker-static']}
              />
            )}
          </div>
          {(enableReset || showApplyButton) && (
            <div className={styles['pulse-datepicker-calendar__buttonsCtn']}>
              {enableReset && (
                <PulseButtonBase variant={ButtonVariants.outlined} onClick={resetCalendar} label="Reset" />
              )}
              {showApplyButton && (
                <PulseButtonBase variant={ButtonVariants.outlined} onClick={onClickApplyHandler} label="Apply" />
              )}
            </div>
          )}
        </div>
        <PulseIconButton
          classes={{
            root: styles['pulse-datepicker-calendar__closeBtn'],
            pulseIcon: {
              icon: 'fal fa-times',
            },
          }}
          handleClick={handleClickAway}
          iconName=""
          size={IconSizes.lg}
        />
        <PulseIconButton
          classes={{
            root: clsx(styles['pulse-datepicker-calendar__nav'], styles['pulse-datepicker-calendar__navNext']),
            pulseIcon: {
              icon: 'fal fa-chevron-right',
            },
          }}
          handleClick={increaseMonth}
          iconName=""
          size={IconSizes.lg}
        />
      </LocalizationProvider>
    </div>
  );
};

export default PulseDatepickerCalendar;
