import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import PulseUserOption from './components/pulse-user-option';
import {
  PulseSelectPropTypes,
  PulseSelectBasePropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { OnChangeValue } from 'react-select';
import styles from './pulse-users-select.module.scss';
import { isFunction } from 'formik';
import { OptionProps } from 'react-select';
import { UserResourceObject } from 'pulse-types/users';

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  label: 'name',
  lastPage: 'last_page',
  type: 'project-users',
  value: 'userid',
};
const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = "Search for a user's name";
const DEFAULT_URL: PulseSelectBasePropTypes['url'] = `/v2/ajax/address-book/users`;

export const PulseProjectUsersSelect = (props: PulseSelectPropTypes): ReactElement => {
  const {
    labelName = 'Users',
    placeholder = DEFAULT_PLACEHOLDER,
    requestFilterKey = 'userId',
    url = DEFAULT_URL,
    valueChangeHandler,
    ...restProps
  } = props;

  const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
    value: OnChangeValue<PulseSelectOptionType<UserResourceObject>, boolean>,
    action,
  ): void => {
    isFunction(valueChangeHandler) &&
      valueChangeHandler(
        {
          [requestFilterKey]: value,
        },
        action,
      );
  };

  return (
    <PulseSelectBase
      changeHandler={handleValueChange}
      dataStructure={DATA_STRUCTURE}
      labelName={labelName}
      placeholder={placeholder}
      url={url}
      classes={{
        root: styles['pulse-user-multi__selectBaseRoot'],
      }}
      components={{
        Option: PulseUserOption as FC<OptionProps<PulseSelectOptionType>>,
      }}
      {...restProps}
    />
  );
};

export default React.memo(PulseProjectUsersSelect);
