import React, { PropsWithChildren, ReactElement } from 'react';
import { PulseTooltipProps } from './pulse-tooltip-types';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import styles from './pulse-tooltip.module.scss';

export const PulseTooltip = (props: PropsWithChildren<PulseTooltipProps>): ReactElement => {
  const { classes, children, contentTooltip, positionTooltip, maxWidth = '288px', TippyProps } = props;

  return (
    <div className={clsx(styles['tooltip--root'], classes?.tooltipRoot)}>
      <Tippy
        theme="arrowPosition"
        arrow
        content={contentTooltip}
        interactive
        placement={positionTooltip || 'auto'}
        maxWidth={maxWidth}
        {...TippyProps}
        className={clsx(styles['pulse-tooltip'], TippyProps?.className)}
      >
        {children ? (
          <div
            data-testid="pulse-tooltip-with-children"
            className={clsx(styles['pulse-tooltip__childrenRoot'], classes?.childrenRoot)}
          >
            {children}
          </div>
        ) : undefined}
      </Tippy>
    </div>
  );
};

export default PulseTooltip;
