import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { TimesheetErrorItemProps, TimesheetErrorDataItem } from './timesheeterroritem-types';
import * as UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Checkbox from '../checkbox/checkbox';
import PulseIconButton from '../pulse-icon-button/pulse-icon-button';
import { toggleClass } from 'pulse-commons/helpers';
import Routes from 'pulse-commons/routes';

import '../../styles/uikit.scss';
import styles from './timesheeterroritem.module.scss';
import { Colors, IconSizes, Sizes } from 'pulse-commons/types';
import Label from '../label/label';
import { isMobileDevice } from 'pulse-commons/helpers';

// loads the UIKit Icon plugin
UIkit.use(Icons);

const buildDetailsSection = (details: TimesheetErrorDataItem['details']): ReactElement[] => {
  const keys = Object.keys(details);
  const detailItemsEl: ReactElement<string>[] = [];
  let key: string;
  for (key of keys) {
    details[key].display &&
      detailItemsEl.push(
        <li key={key} className="uk-text-muted">
          <span>{details[key].label}:</span> {details[key].value}
        </li>,
      );
  }
  return detailItemsEl;
};

const TimesheetErrorItem = (props: TimesheetErrorItemProps): ReactElement => {
  const { errorData, resubmitHandler, selectHandler } = props;
  const {
    id,
    agresso_timesheet_error_message: { id: agressoErrorId, pretty_error: errorMessage, project_id },
    details,
    archive_by,
    user: { userid: userid, name: userName, email: userEmail },
    selected = false,
    isResubmitting,
  } = errorData;

  const timesheetErrorItem: React.MutableRefObject<any> = useRef(null);
  const [open, setOpen] = useState(false);

  const toggleDetail = (): void => setOpen(!open);

  const openUserCard = useCallback((): void => {
    window.open(Routes.addressBookUser({ userid }));
  }, [errorData]);

  const selectTimehseetErrorItem = (e: React.ChangeEvent<HTMLInputElement>): void => {
    selectHandler(agressoErrorId, id, e.currentTarget.checked);
  };

  const resubmitTimesheetErrorItem = (): void => {
    resubmitHandler([agressoErrorId], [id]);
  };

  return (
    <div
      data-testid="timesheet-error-item__ctn"
      className={toggleClass({
        defaultClasses: [styles['timesheet-error-item-ctn']],
        toggleStyleName: styles.selected,
        toggleState: selected,
      })}
      ref={timesheetErrorItem}
    >
      <div className={`${styles['timesheet-error-item__id']} uk-width-1-6@s`}>
        <span className={`${styles['timesheet-error-item__mobileLabel']}`}>Timesheet Date</span>
        <div>
          <a
            href={Routes.userTimesheet({ userId: userid, selectedDate: errorData.details.dateFrom.value })}
            target="_blank"
            rel="noreferrer"
          >
            {errorData.details.dateRange?.value || ''}
          </a>
        </div>
        <label
          data-testid="timesheet-error-item__detailBtn"
          className={`uk-visible@s ${styles['timesheet-error-item__detailBtn']}`}
          onClick={toggleDetail}
        >
          <span
            className={toggleClass({
              defaultClasses: [styles['timesheet-error-item__icon']],
              toggleStyleName: styles.open,
              toggleState: open,
            })}
            uk-icon="icon:chevron-right;ratio:0.8"
          ></span>
          Details
        </label>
      </div>
      <div className={`${styles['timesheet-error-item__user']} uk-text-truncate uk-width-1-6@s`}>
        <span className={`${styles['timesheet-error-item__mobileLabel']}`}>User</span>
        <div>
          <div uk-tooltip="title: User ID; pos: left;">
            <span className={styles['timesheet-error-item__icon']} uk-icon="icon:hashtag;ratio:0.8"></span>
            {userid}
          </div>
          <div uk-tooltip="title: User Name; pos: left;">
            <span className={styles['timesheet-error-item__icon']} uk-icon="icon:user;ratio:0.8"></span>
            {userName}
            <PulseIconButton
              classes={{
                root: [styles['timesheet-error-item__userBtn'], 'uk-visible@s'],
                pulseIcon: {
                  icon: 'fal fa-external-link',
                },
              }}
              iconName=""
              handleClick={openUserCard}
              size={IconSizes.sm}
              tooltip="Open user card"
            />
          </div>
          <div uk-tooltip="title: User Email; pos: left;">
            <span className={styles['timesheet-error-item__icon']} uk-icon="icon:mail;ratio:0.8"></span>
            {userEmail}
          </div>
        </div>
      </div>
      <div className={`${styles['timesheet-error-item__description__ctn']} uk-width-1-6@s`}>
        <span className={`${styles['timesheet-error-item__mobileLabel']}`}>Project ID</span>
        {project_id && (
          <a href={Routes.projectByIdPassport({ projectId: project_id })} target="_blank" rel="noreferrer">
            <span className={styles['timesheet-error-item__icon']} uk-icon="icon:link;ratio:0.8"></span>
            {project_id}
          </a>
        )}
        {!project_id && <p>No Project ID recorded.</p>}
      </div>
      <div className={`${styles['timesheet-error-item__description__ctn']} uk-width-expand@s`}>
        <span className={`${styles['timesheet-error-item__mobileLabel']}`}>Error Response</span>
        <p>{errorMessage}</p>
        {/* Here for mobile support */}
        <label
          data-testid="timesheet-error-item__detailBtn"
          className={`${styles['timesheet-error-item__detailBtn']}`}
          onClick={toggleDetail}
        >
          <span
            className={toggleClass({
              defaultClasses: [styles['timesheet-error-item__icon']],
              toggleStyleName: styles.open,
              toggleState: open,
            })}
            uk-icon="icon:chevron-right;ratio:0.8"
          ></span>
          Details
        </label>
        {isMobileDevice() && open && (
          <div data-testid="timesheet-error-item__details" className={styles['timesheet-error-item__details--mobile']}>
            <ul>{details && buildDetailsSection(details).map(el => el)}</ul>
          </div>
        )}
      </div>
      {!archive_by && details.status && details.status.value.toLowerCase() === 'failed' && (
        <>
          <div
            data-testid="timesheet-error-item__refreshBtn"
            className={`uk-text-right uk-width-1-1 uk-width-auto@s ${styles['timesheet-error-item__action']}`}
          >
            <PulseIconButton
              classes={{
                root: styles['timesheet-error-item__refreshBtn'],
                pulseIcon: {
                  icon: 'fal fa-redo',
                },
              }}
              disabled={isResubmitting}
              color={Colors.success}
              iconName=""
              handleClick={resubmitTimesheetErrorItem}
              tooltip="Resubmit"
            />
          </div>
          <div
            data-testid="timesheet-error-item__checkbox"
            className={`uk-width-1-2 uk-width-auto@s ${styles['timesheet-error-item__action']} ${styles['timesheet-error-item__checkbox']}`}
          >
            <Checkbox
              color="secondary"
              label="Select"
              labelPlacement="right"
              onClick={selectTimehseetErrorItem}
              checked={selected}
            />
          </div>
        </>
      )}
      {archive_by && (
        <div>
          <Label color={Colors.disabled} text="Archived" size={Sizes.sm} />
        </div>
      )}
      {!archive_by && details.status && details.status.value.toLowerCase() === 'processing' && (
        <div>
          <Label color={Colors.warning} text="Processing" size={Sizes.md} />
        </div>
      )}
      {!isMobileDevice() && open && (
        <div data-testid="timesheet-error-item__details" className={styles['timesheet-error-item__details']}>
          <ul>{details && buildDetailsSection(details).map(el => el)}</ul>
        </div>
      )}
    </div>
  );
};

export default TimesheetErrorItem;
